<template>
  <section class='card-content'>
    <section style='background: #FFFFFF'>
      <component :is='component' @next='next' />
    </section>
  </section>
</template>

<script>
import PersonalData from '@/views/Access/UserProfile/Steps/PersonalData'
import Confirmation from '@/views/Access/UserProfile/Steps/Confirmation'

export default {
  namer: 'Signup',
  components: {
    PersonalData,
    Confirmation,
  },
  computed: {
  },
  data() {
    return {
      component: 'PersonalData',
      components: {
        1: 'PersonalData',
        2: 'Confirmation',
      },
    }
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
  },
  methods: {
    next(step) {
      this.component = this.components[step]
    },

    login() {
      this.$store.dispatch('access/updateError', '')
      this.$router.push({ path: '/login' })
    },
  }
}
</script>

<style scoped>
.card-content{
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: 20px!important;
}
@media only screen and (max-width: 1000px) {
  .card-content{
    margin: 0 15px!important;
  }
}
</style>
